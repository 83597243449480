import { Loader } from '@googlemaps/js-api-loader';
import useTranslate from "components/language/useTranslate";
import { useRef, useEffect } from 'react';

const libraries = ['places'];
export default function GooglePost({handleZipcode, handleAddress, setModalIsOpen}) {
    const t = useTranslate();
    const searchBoxRef = useRef(null);
    const inputRef = useRef(null);
    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    const COUNTRY = process.env.REACT_APP_COUNTRY;
    const LANGUAGE = process.env.REACT_APP_LANGUAGE;

    useEffect(() => {
        // 구글 맵 API를 로드하는 Loader 객체 생성
        const loader = new Loader({
            apiKey: apiKey,
            version: 'weekly',
            libraries: libraries,
            region: {COUNTRY},
            language: {LANGUAGE}, 
        });

        loader.importLibrary('places').then(() => {
            if (inputRef.current) {
                const searchBox = new window.google.maps.places.SearchBox(inputRef.current);
                searchBox.addListener('places_changed', () => onPlacesChanged(searchBox));
                searchBoxRef.current = searchBox;
            }
        });

        return () => {
            if (searchBoxRef.current) {
                window.google.maps.event.clearInstanceListeners(searchBoxRef.current);
            }
        };
    }, []);

    const onPlacesChanged = () => {
        const places = searchBoxRef.current.getPlaces();
        if (!places || places.length === 0) return;

        const place = places[0];
        if (!place || !place.address_components) return;

        const addressComponents = place.address_components;

        handleAddress(place.formatted_address);
        
        const postalCodeComponent = addressComponents.find(component => component.types.includes('postal_code'));
        if (postalCodeComponent) {
            handleZipcode(postalCodeComponent.long_name);
        }

        setModalIsOpen(false);
    };

    return (
        <div>
            <input
                ref={inputRef}
                id="searchAddr"
                type="text"
                placeholder={t("common_searchAddr")}
                className='inp'
                style={{ width: '100%', marginBottom: '10px' }}
            />
        </div>
    )
}